.MainLogo {
    height: 75.6px;
    width: 320px; 
}

.nav-link {
    color: white !important;
}

a.nav-link {
    height: min-content;
}

a.nav-link:hover {
    background-color: white !important;
    color: #008080 !important;
}

#colorBar {
    background-color: #008080;
    height: 5px;
}

.MainBodyElement {
    margin: 0 auto;
    border: 4px solid black;
    background: #D2D2D2;
}

.mainNavBar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 16px;
}
.navEl {
    float: left;
    color: white;
    margin: 0;
    padding: 3px;
    border-style: dotted;
    border-color: #D2D2D2;
}
.a {
    padding:8px;
    text-decoration: none;
    color:white;
}
.a:hover {
  background-color: #008080;
  color:white;
}
.dropbtn {
  color: white;
  border: none;
  cursor: pointer;
  text-decoration:none;
}

.mainDropDown {
  float: left;
  color: white;
  border-style: dotted;
  border-color: #D2D2D2;
  padding:3px;
}

.mainDropDown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  background: black;
  color:white
}

.dropDownItem {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align:'left';
  border: 2px dotted #D2D2D2;
}

.dropDownItem:hover {background-color: #008080; color:white;}

.mainDropDown:hover .mainDropDown-content {
  display: block;
}

.mainDropDown:hover {
  background-color: #008080;
}
.dropbtn:hover {
  color:white;
}

.walletEl {
    float: right;
    color: white;
    margin-left: auto;
    padding-left: 5em;
    font-size:12px;
}

.VillainDetailsTable{
    width: 80% !important;
    margin: 0 auto;
}
.Modal {
    width:80%;
    height:60%;
    background: #D2D2D2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    padding: 1%;
    border: 10px solid black;
  }

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 128, 128, 0.85);
}

.Login{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    background:#D2D2D2;
    padding:1%;
}
