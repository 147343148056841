@font-face {
font-family: "DotBoundary";
src: local("DotBoundary"),
 url("./fonts/DotBoundary/dotboundary-fixed.otf") format("opentype");
}

@font-face {
font-family: "PressStart2P";
src: local("PressStart2P"),
 url("./fonts/PressStart2P.ttf") format("truetype");
}
