.EventsTable {
    table-layout : auto !important;
    margin-left: auto !important;
    width: 75%;
    margin-right: auto !important;
}

.EventsControlButton {
    display: flex !important;
}

.EventsChoiceToggle.active {
    background:#008080 !important;
}

.table-bordered{
    border:2px solid black !important;
}
